<div #fullScreenElement>
  <nz-spin [nzSpinning]="isLoading">
    <nz-alert (click)="showFilter = true" style="cursor:pointer;" class="m-b-10 m-10"
              *ngIf="(myTeamResources?.length <= 0 && myResources?.length <= 0) && !isLoading"
              nzType="error"
              nzMessage="Es wurden keine Ressourcen ausgewählt. Sie sollten mindestens einen Mitarbeiter oder Team auswählen."></nz-alert>


    <nz-drawer [nzClosable]="true" [nzVisible]="showFilter" nzPlacement="right" nzTitle="Anzeige konfigurieren"
               (nzOnClose)="filtersClosed()">
      <ng-container *nzDrawerContent>
        <div class="inline-block">
          <label class="m-t-5" nz-checkbox [(ngModel)]="checked" [nzIndeterminate]="indeterminate"
                 (nzCheckedChange)="checkAllUser()"><h4 class="inline-block m-0">Mitarbeiter</h4></label>
        </div>
        <hr class="m-v-10">
        <div style="border-right: {{ user.color || 'transparent' }} solid 5px" class="m-b-5"
             *ngFor="let user of myUserResourcesAvailable">

          <label (nzCheckedChange)="updateSingleChecked()" nz-checkbox [(ngModel)]="user.checked">
            <nz-avatar nzSize="small" class="m-r-5"
                       [nzSrc]="user.avatarPath ? backendURL + '/' + user.avatarPath : ''"
                       [nzIcon]="user.avatarPath ? backendURL + '/' + user.avatarPath : 'user'"
                       style="vertical-align: middle;"
            ></nz-avatar>
            {{ user.name }}</label>
        </div>


        <label class="m-t-15" nz-checkbox [(ngModel)]="checkedTeam" [nzIndeterminate]="indeterminateTeam"
               (nzCheckedChange)="checkAllTeam()"><h4 class="inline-block m-0">Teams</h4></label>
        <hr class="m-v-10">
        <div class="m-b-5" *ngFor="let team of myTeamResourcesAvailable">
          <label (nzCheckedChange)="updateSingleTeamChecked()" nz-checkbox
                 [(ngModel)]="team.checked">{{ team.name }}</label>
        </div>

        <hr class="m-v-15">

        <h5>Objekt/Kontakt wählen <i nz-icon nzType="info-circle"
                                    nz-tooltip="Wenn Sie hier ein Objekt oder einen Kontakt auswählen, werden nur noch die entsprechenden Aufgaben angezeigt."
                                    style="font-size: 15px;" class="m-l-5"
                                    nzTheme="outline"></i></h5>

        <app-object-customer-select
          [(reference)]="filter.reference"
          [multiple]="true" [hideLabel]="true"
          [allowClear]="true"></app-object-customer-select>


        <hr class="m-v-15">

        <button class="m-t-10" nz-button nzType="default" (click)="filtersClosed()" nzBlock>Fertig</button>
      </ng-container>
    </nz-drawer>

    <div style="position: relative" nz-row>
      <div nz-col [nzSpan]="showBacklog ? 6 : 0" class="p-r-5">
        <a nz-button nzBlock nzType="link" (click)="getBacklog()"><i nz-icon nzType="reload" nzTheme="outline"></i>
          Aktualisieren</a>
        <nz-collapse>
          <nz-collapse-panel nzActive="true"
                             [nzHeader]="(wasteplanSchedulerListComponent?.myEvents?.length || '') + ' Abfuhrpläne'">
            <nz-alert *ngIf="view !== 'day' && view !== 'timeline'; else elseif1"
                      nzType="warning"
                      nzMessage="Ansicht wechseln"
                      nzDescription="Abfuhrpläne können nur mit der Ansicht 'Tag' oder 'Timeline' eingeplant werden"
            ></nz-alert>
            <ng-template #elseif1>
              <app-wasteplan-scheduler-list #wasteplanSchedulerListComponent></app-wasteplan-scheduler-list>

            </ng-template>
          </nz-collapse-panel>
          <nz-collapse-panel nzActive="true" [nzHeader]="backlog.length + ' Backlogs'">
              <div *ngFor="let task of backlog;let i = index" class="m-b-5">
                <task-tag mbsc-draggable [dragData]="task" (onClose)="getBacklog()"
                         [taskId]="task?.id"></task-tag></div>

            <span *ngIf="backlog?.length <= 0">Aktuell sind keine Aufgaben zu verplanen
            </span>
          </nz-collapse-panel>
        </nz-collapse>
        <a nz-button nzBlock nzType="link" (click)="showBacklog = false">Backlog schließen</a>
      </div>
      <div class="full-height-container" nz-col [nzSpan]="showBacklog ? 18 : 24">


        <mbsc-eventcalendar
          #mbscEventcalendar
          *ngIf="myUserResourcesAvailable"
          [data]="myEvents"
          [options]="eventSettings"
          [view]="calView"
          [dragTimeStep]="this.timeCellSteps[this.timeCallStepsActive].dragTimeStep || 15"
          [resources]="myResources"
          [headerTemplate]="customTemplate"
          [labelContentTemplate]="labelTemplate"
          [scheduleEventContentTemplate]="eventTemplate"
          [eventContentTemplate]="agendaTemplate"
          [clickToCreate]="view === 'month' ? false : 'double'"
          [dragToCreate]="(view !== 'month')"
          [dragToMove]="true"
          [dragToResize]="(view !== 'month')"
          [externalDrop]="(view !== 'month')"
          [extendDefaultEvent]="newEventData"
          (onSelectedDateChange)="wasteplanSchedulerListComponent?.getWastePlans($event)"
          (onPageLoading)="setCalendarRange($event)"
          [resourceTemplate]="resourceTemp">


          <!-- Header -->
          <ng-template #customTemplate>

            <mbsc-calendar-nav class="cal-header-nav"></mbsc-calendar-nav>


            <div class="cal-header-picker">
              <mbsc-segmented-group [theme]="'ios'" [(ngModel)]="view" (change)="changeView()">
                <mbsc-segmented [theme]="'ios'" value="month">Monat</mbsc-segmented>
                <mbsc-segmented [theme]="'ios'" value="week">Woche</mbsc-segmented>
                <mbsc-segmented [theme]="'ios'" value="day">Tag</mbsc-segmented>
                <mbsc-segmented [theme]="'ios'" value="agenda">Liste</mbsc-segmented>
                <mbsc-segmented [theme]="'ios'" value="timeline">Timeline</mbsc-segmented>
              </mbsc-segmented-group>

            </div>


            <button *appIsManager nz-button nzType="default" (click)="showFilter = true" class="m-r-15"
                    [nzDanger]="myUserResources?.length <= 0 && myTeamResources?.length <= 0">
              <i nz-icon nzType="user" nzTheme="outline"></i> {{ myUserResources.length }}
              <ng-container *ngIf="myTeamResources.length > 0">
                <i nz-icon nzType="team" nzTheme="outline"></i> {{ myTeamResources.length }}
              </ng-container>
              <ng-container *ngIf="filter.reference?.length > 0">
                <i class="m-r-5" nz-icon nzType="home"
                   nzTheme="outline"></i> {{ filter.reference.length }}

              </ng-container>
            </button>


            <nz-badge class="m-r-15" *appIsManager style="z-index: 99999;"
                      [nzCount]="backlog.length + wasteplanSchedulerListComponent?.myEvents?.length"
                      [nzOverflowCount]="10">
              <button *appIsManager nz-button nzType="default" (click)="showBacklog = !showBacklog"><i nz-icon
                                                                                                       nzType="inbox"
                                                                                                       nzTheme="outline"></i>
                Backlog
              </button>
            </nz-badge>

            <button class="m-r-5" nz-button nzType="default" nz-dropdown *appIsManager [nzDropdownMenu]="settingsDrop">
              Mehr
              <span nz-icon nzType="down"></span>
            </button>
            <nz-dropdown-menu #settingsDrop="nzDropdownMenu">
              <ul nz-menu>
                <!--                <li nz-menu-item (click)="globalSearch()"><i class="m-r-10" nz-icon nzType="search"
                                                                             nzTheme="outline"></i> Suche</li>-->
                <li nz-menu-item (click)="calendarSetting()"><i class="m-r-10" nz-icon nzType="setting"
                                                                nzTheme="outline"></i> Globale Einstellungen
                </li>
                <li nz-submenu [nzTitle]="notificationTitle">
                  <ng-template #notificationTitle><i nz-icon nzType="bell" class="m-r-10" nzTheme="outline"></i>
                    Benachrichtigungen
                  </ng-template>
                  <ul>
                    <li (click)="changeNotificationMode('never')" nz-menu-item><i class="m-r-10" nz-icon nzTheme="outline" [nzType]="settings.notificationMode === 'never' ? 'check-square' : 'border'"></i> Keine
                      Benachrichtigungen senden
                    </li>
                    <li (click)="changeNotificationMode('always')" nz-menu-item><i class="m-r-10" nz-icon nzTheme="outline" [nzType]="settings.notificationMode === 'always' ? 'check-square' : 'border'"></i>
                      Automatisch Benachrichtigungen senden
                    </li>
                    <li (click)="changeNotificationMode('ask')" nz-menu-item><i class="m-r-10" nz-icon nzTheme="outline" [nzType]="settings.notificationMode === 'ask' ? 'check-square' : 'border'"></i> Jedes mal
                      fragen
                    </li>
                  </ul>
                </li>
                <li nz-menu-item (click)="taskImport()"><i class="m-r-10" nz-icon nzType="upload"
                                                           nzTheme="outline"></i> ICS-Import
                </li>
                <li nz-menu-item (click)="printPDF()"><i class="m-r-10" nz-icon nzType="file-pdf"
                                                         nzTheme="outline"></i> Ansicht exportieren (PDF)
                </li>
              </ul>
            </nz-dropdown-menu>

            <mbsc-calendar-prev class="cal-header-prev"></mbsc-calendar-prev>
            <mbsc-calendar-today class="cal-header-today"></mbsc-calendar-today>
            <mbsc-calendar-next class="cal-header-next"></mbsc-calendar-next>

            <button nz-button nzType="link" (click)="fullScreen()"><i nz-icon
                                                                      [nzType]="!doc.fullscreenElement ? 'fullscreen' : 'fullscreen-exit'"
                                                                      nzTheme="outline"></i></button>

          </ng-template>
        </mbsc-eventcalendar>


        <ng-template #resourceTemp let-resource>
          <nz-avatar nzSize="small" class="m-r-5"
                     [nzSrc]="resource.avatarPath ? backendURL + '/' + resource.avatarPath : ''"
                     [nzIcon]="resource.avatarPath ? backendURL + '/' + resource.avatarPath : 'user'"
                     style="vertical-align: middle;"
          ></nz-avatar>
          <span style="padding-right: 5px; border-bottom: {{ resource.color }} solid 2px"
                [ngClass]="{'text-primary' : resource.type === 'team'}">
          <i *ngIf="resource.type === 'team'" nz-icon nzType="team" nzTheme="outline"></i> {{ resource.name }}
        </span>
        </ng-template>

        <!-- Event -->
        <ng-template #eventTemplate let-data>
          <div style="height: 100%" nz-popover
               [nzPopoverContent]="eventTemplateDetailsActions"
               nzPopoverPlacement="top">

            <label #eventDone class="small-checkbox" nz-checkbox [nzChecked]="recurringDone(data)"
                   (nzCheckedChange)="setTaskDone(data, $event.valueOf(), eventDone)"></label>

            {{ data.title }}

            <i *ngIf="data?.original.recurring" class="m-l-5" nz-icon nzType="sync" nzTheme="outline"></i>

            <i *ngIf="data?.original.tagNames?.length" class="m-l-5 text-primary" nz-icon nzType="tag" nzTheme="fill" [title]="data?.original.tagNames.join(', ')"></i>

            <div *ngIf="data?.original.object">
              <small><i nz-icon nzType="home" nzTheme="outline"></i> {{ data?.original.object?.name }}
                <ng-container *ngIf="data?.original?.room"><i nz-icon nzType="right"
                                                             nzTheme="outline"></i>  {{ data?.original?.room?.name }}
                </ng-container>
              </small>
            </div>
            <div *ngIf="data?.original.contact">
              <small><i nz-icon nzType="idcard" nzTheme="outline"></i> {{
                  data?.original.contact?.name
                }} </small>
            </div>

            <!--Hover über event-->
            <ng-template #eventTemplateDetailsActions>
              <div class="text-bold">{{ data.title }}</div>
              <nz-tag class="m-t-5 pointer display-block" *ngIf="data?.original.object"
                      [routerLink]="'/objects/' + data?.original.object?.id" [nzColor]="'blue'"><i nz-icon
                                                                                                  nzType="home"
                                                                                                  nzTheme="outline"></i> {{ data?.original.object?.name }}
                <ng-container *ngIf="data?.original.room">{{ ' > ' + data?.original.room?.name }}</ng-container>
              </nz-tag>

              <nz-tag class="m-t-5 display-block" *ngIf="data?.original.contact" [nzColor]="'blue'"><i nz-icon
                                                                                                       nzType="idcard"
                                                                                                       nzTheme="outline"></i> {{ data?.original.contact?.name }}
              </nz-tag>


              <nz-tag class="m-t-5 pointer display-block" *ngIf="data?.original.ticket_id"
                      [routerLink]="'/tickets/' + data?.original.ticket_id" [nzColor]="'blue'"><i nz-icon
                                                                                                 nzType="inbox"
                                                                                                 nzTheme="outline"></i>
                Ticket ansehen
              </nz-tag>

              <app-entity-tag-selector entityType="task" [readOnly]="true" nzMaxTagCount="2" [entity]="data?.original"></app-entity-tag-selector>

              <small *ngIf="data?.original.user"> <i nz-icon nzType="user"
                                                    nzTheme="outline"></i> {{ data?.original.user?.name }}
              </small>
              <br>

              <small *ngIf="data?.original.recurring">
                <i nz-icon nzType="sync" nzTheme="outline"></i> Diese Aufgabe wiederholt sich automatisch.<br> Zum
                Bearbeiten aller Vorkommnisse, müssen Sie <br>das
                Ereignis durch klicken editieren.
              </small>
            </ng-template>
          </div>

        </ng-template>

        <ng-template #labelTemplate let-data>
          <div style="height: 100%" class="d-flex" nz-popover [nzPopoverTitle]="data?.title"
               [nzPopoverContent]="eventTemplateDetailsActions" nzPopoverPlacement="left">

            <!--Hover über event-->
            <ng-template #eventTemplateDetailsActions>


              <nz-tag class="m-t-5 pointer display-block" *ngIf="data?.original.object"
                      [routerLink]="'/objects/' + data?.original.object?.id" [nzColor]="'blue'"><i nz-icon
                                                                                                  nzType="home"
                                                                                                  nzTheme="outline"></i> {{ data?.original.object?.name }}
                <ng-container *ngIf="data?.original.room">{{ ' > ' + data?.original.room?.name }}</ng-container>
              </nz-tag>

              <nz-tag class="m-t-5 pointer display-block" *ngIf="data?.original.contact"
                      [routerLink]="'/objects/' + data?.original.object?.id" [nzColor]="'blue'"><i nz-icon
                                                                                                  nzType="idcard"
                                                                                                  nzTheme="outline"></i> {{ data?.original.contact?.name }}
              </nz-tag>


              <nz-tag class="m-t-5 pointer display-block" *ngIf="data?.original.ticket_id"
                      [routerLink]="'/tickets/' + data?.original.ticket_id" [nzColor]="'blue'"><i nz-icon
                                                                                                 nzType="inbox"
                                                                                                 nzTheme="outline"></i>
                Ticket ansehen
              </nz-tag>

              <app-entity-tag-selector entityType="task" [readOnly]="true" nzMaxTagCount="2" [entity]="data?.original"></app-entity-tag-selector>

              <small *ngIf="data?.original.user"> <i nz-icon nzType="user"
                                                    nzTheme="outline"></i> {{ data?.original.user?.name }}
              </small>
              <br>

              <div class="recurring">
                <i *ngIf="data?.original.recurring" nz-tooltip nzTooltipTitle="Diese Aufgabe wiederholt sich automatisch. Zum Bearbeiten aller Vorkommnisse, müssen Sie das
              Ereignis durch klicken editieren." nz-icon nzType="sync" nzTheme="outline"></i>
              </div>
            </ng-template>
            <i *ngIf="data?.original.done" nz-icon nzType="check-circle" nzTheme="outline" class="m-r-5"></i>
            <i *ngIf="data?.original.recurring" nz-icon nzType="sync" nzTheme="outline" class="m-r-5"></i> {{ data?.title }}
            <i *ngIf="data?.original.tagNames?.length" class="m-l-auto text-primary" nz-icon nzType="tag" nzTheme="fill" [title]="data?.original.tagNames.join(', ')"></i>
          </div>
        </ng-template>

        <!-- Agenda -->
        <ng-template #agendaTemplate let-data>
          <label #agendaDone class="small-checkbox" nz-checkbox [nzChecked]="recurringDone(data)"
                 (nzCheckedChange)="setTaskDone(data, $event.valueOf(), agendaDone)"></label>

          {{ data.title }} <i class="m-h-5" *ngIf="data?.original.recurring" nz-icon nzType="sync" nzTheme="outline"></i>

          <nz-tag *ngFor="let tag of data?.original.tags" [nzColor]="tag.color"
            style="font-size: 10px; padding: 0 10px; line-height: 25px; margin: 0 4px;">{{tag.name}}
          </nz-tag>

          <div class="m-t-5">
            <small class="m-r-20" *ngIf="data?.original.object"><i nz-icon nzType="home"
                                                                  nzTheme="outline"></i> {{ data?.original.object?.name }}
            </small>
            <small class="m-r-20" *ngIf="data?.original.contact"><i nz-icon nzType="idcard"
                                                                    nzTheme="outline"></i> {{
                data?.original.contact?.name
              }}
            </small>
            <small *ngIf="data?.original.user"><i nz-icon nzType="user"
                                                 nzTheme="outline"></i> {{ data?.original.user?.name }}
            </small>
          </div>

        </ng-template>
      </div>
    </div>
    <ng-container *ngIf="!hidebtn">
      <nz-button-group class="floating-btn">
        <button class="hover" nz-button (click)="decreaseView()"
                [disabled]="this.timeCallStepsActive <= 0" nzType="primary"><span nz-icon nzType="plus"></span>
        </button>
        <button class="hover" nz-button (click)="increaseView()"
                [disabled]="this.timeCallStepsActive >= this.timeCellSteps.length-1"
                nzType="primary"><span nz-icon nzType="minus"></span></button>
      </nz-button-group>
    </ng-container>
  </nz-spin>


  <ng-template let-task="data" let-notification #notificationTemplate>
    <nz-tag [nzColor]="task.color">{{ task.title }}</nz-tag>
    <p>Die Aufgabe wurde gespeichert. Möchten Sie die verantwortliche Person informieren?</p>

    <button (click)="sendTaskNotification(task, notification)" nz-button nzType="primary" nzSize="small"><i nz-icon
                                                                                                            nzType="bell"
                                                                                                            nzTheme="outline"></i>
      Ja
    </button>
    <button (click)="notification.close()" class="m-l-10" nz-button nzSize="small">Nein <span
      class="m-l-10 color-orange">{{ task._countdown }}</span></button>
  </ng-template>
</div>

