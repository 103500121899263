<nz-spin [nzSpinning]="isLoading">

  <h2 class="m-0">Globale Kalender Einstellungen</h2>
  <p class="m-v-10">Diese Einstellungen gelten für alle Nutzer der Anwendung. Konfigurieren Sie daher mit äußerster Vorsicht. Aufgaben, welche außerhalb der konfigurierten Zeiten liegen, werden eventuell nicht mehr sichtbar sein!</p>
  <nz-alert *ngIf="notAdmin" nzType="warning" nzMessage="Diese Einstellungen können nur von einem Administrator geändert werden!"></nz-alert>
  <hr class="m-v-15">
  <div nz-col>
    <div nz-row>
      <nz-form-item nz-col nzSpan="12" class="p-r-5">
        <nz-form-label nzFor="start">Tage von</nz-form-label>
        <nz-form-control nzErrorTip="Wählen Sie einen Tag">
          <nz-select [disabled]="notAdmin" [(ngModel)]="config.startDay" style="width: 100%" nzPlaceHolder="Start Tag wählen">
            <nz-option *ngFor="let day of days" [nzValue]="day.day" [nzLabel]="day.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-col nzSpan="12" class="p-r-5">
        <nz-form-label nzFor="end">bis</nz-form-label>
        <nz-form-control nzErrorTip="Wählen Sie einen Tag">
          <nz-select [disabled]="notAdmin" [(ngModel)]="config.endDay" style="width: 100%" nzPlaceHolder="Start Tag wählen">
            <nz-option *ngFor="let day of days" [nzValue]="day.day" [nzLabel]="day.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-row>
      <nz-form-item nz-col nzSpan="12" class="p-r-5">
        <nz-form-label nzFor="startHour">Stunden von</nz-form-label>
        <nz-form-control>
          <mbsc-datepicker
            [disabled]="notAdmin"
            [(ngModel)]="config.startTime"
            [controls]="['timegrid']"
            [touchUi]="false"
            [timezonePlugin]="momentPlugin"
            labelStyle="stacked"
            dataTimezone="local"
            displayTimezone="local"
            theme="ios"
            inputStyle="outline"
            [stepMinute]="60">
          </mbsc-datepicker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-col nzSpan="12" class="p-r-5">
        <nz-form-label nzFor="endHour">bis</nz-form-label>
        <nz-form-control>
          <mbsc-datepicker
            [disabled]="notAdmin"
            [(ngModel)]="config.endTime"
            [controls]="['timegrid']"
            [touchUi]="false"
            [timezonePlugin]="momentPlugin"
            labelStyle="stacked"
            dataTimezone="local"
            displayTimezone="local"
            theme="ios"
            inputStyle="outline"
            [stepMinute]="60">
          </mbsc-datepicker>
        </nz-form-control>
      </nz-form-item>
    </div>

    <hr class="m-v-15">

    <div class="inline-block">
      <button [disabled]="notAdmin"
              (click)="reset()"
              nz-button
              nzDanger
              nzType="link"
              type="reset">
        Zurücksetzen
      </button>
    </div>

    <div class="pull-right inline-block">
      <button (click)="cancel()"
              class="m-r-10"
              nz-button
              nzType="default"
              type="button">Abbrechen
      </button>

      <button [disabled]="notAdmin"
              (click)="updateSettings()"
              nz-button
              nzType="primary"
              type="submit">
        Speichern
      </button>
    </div>
  </div>

</nz-spin>
