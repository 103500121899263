import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as ICAL from 'ical.js';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {environment} from '../../../environments/environment';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {ApiService} from '../../services/api.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ActivatedRoute, Router} from '@angular/router';
import {Contact, Object, Task, Team, User} from '../../../../../database-models';
import * as uuid from 'uuid';

// @ts-ignore
import moment from 'moment-timezone';

@Component({
  selector: 'app-task-import',
  templateUrl: './task-import.component.html',
  styleUrls: ['./task-import.component.css']
})
export class TaskImportComponent implements OnInit {

  public checked = true;
  public indeterminate = true;
  public uploadCompleted: boolean = false;
  public loading: boolean = false;
  public setOfCheckedId = new Set<string>();

  public listOfCurrentPageData: any[] = [];
  public events: Task[] = [];
  public selectedEvents: Task[] = [];

  public reference: Object | Contact;


  public users: User[];
  public teams: Team[];
  public objects: Object[];
  public contacts: Contact[];

  public backendURL = environment.apiURL;
  public current: number = 0;

  constructor(
    public autenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    public api: ApiService,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private message: NzMessageService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getUsers();
    this.getTeams();
    this.getObjects();
    this.getContacts();
  }

  public getUsers(): void {
    this.api.getUsers().subscribe(
      (employees: User[]) => {
        this.users = employees;
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Mitarbeiter konnten nicht geladen werden');
      }
    );
  }

  public getObjects(): void {
    this.api.getObjects().subscribe((objects: Object[]) => {
      this.objects = objects;
      // this.initForm();
    }, onerror => {
      console.log(onerror);
      this.message.error('Objekte konnten nicht geladen werden');
    });
  }

  public getContacts(): void {
    this.api.getContacts().subscribe((contacts: Contact[] | any) => {
      this.contacts = contacts;
    }, onerror => {
      console.log(onerror);
      this.message.error('Fehler beim Laden der Daten');
    });
  }

  public getTeams(): void {
    this.api.getTeam().subscribe(
      (teams: Team[]) => {
        this.teams = teams;
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Teams konnten nicht geladen werden');
      }
    );
  }

  public handleFileUpload(event: NzUploadChangeParam) {
    const file: NzUploadFile = event.file;
    if (file.status === 'error' && !this.uploadCompleted) { // "error" um den API Call zu ignorieren.
      const reader = new FileReader();

      reader.onload = () => {
        const calendarData = reader.result as string;
        const events = this.ics(calendarData);
        this.events = events;
        this.events.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          // @ts-ignore
          return new Date(b.start) - new Date(a.start);
        });
        this.events.forEach(event => {
          this.onItemChecked(event.id, true);
        });
        if (events.length > 0) {
          this.current += 1;
        }
        this.uploadCompleted = true;
        this.cdr.detectChanges();
      };
      const blob = new Blob([file.originFileObj], {type: file.type});
      reader.readAsText(blob);
    }
  }

  public ics(calendarData: string): ICAL.Event[] {
    const jCalData = ICAL.parse(calendarData);
    const comp = new ICAL.Component(jCalData);
    const vevents = comp.getAllSubcomponents('vevent');
    let importId = uuid.v4();

    return vevents.map(vevent => {
      const event = new ICAL.Event(vevent);
      event.id = uuid.v4();
      event.import_id = importId;
      event.title = vevent.getFirstPropertyValue('summary');
      try {
        event.description = vevent.getFirstPropertyValue('location');
      } catch (e) {

      }


      const startDate = vevent.getFirstPropertyValue('dtstart');
      const endDate = vevent.getFirstPropertyValue('dtend');

      const isAllDay = startDate.hour === 0 && startDate.minute === 0 && endDate.hour === 0 && endDate.minute === 0;

      if (isAllDay) {
        event.allDay = true;
        event.start = moment(startDate.toJSDate()).format('YYYY-MM-DD');
        event.end = moment(endDate.toJSDate()).subtract(1, 'day').format('YYYY-MM-DD');
      } else {
        event.allDay = false;
        event.start = moment(startDate.toJSDate()).toISOString();
        event.end = moment(endDate.toJSDate()).toISOString();
      }

      return event;
    });
  }

  public save(): void {

    this.modalService.warning({
      nzTitle: 'Es werden ' + this.setOfCheckedId.size + ' Aufgaben erzeugt',
      nzContent: 'Sind Sie sicher, dass Sie ' + this.setOfCheckedId.size + ' neue Aufgaben erzeugen möchten? Diese Aktion lässt sich nicht rückgängig machen!',
      nzOnOk: () => {
        this.loading = true;
        this.selectedEvents = this.events.filter(event => this.setOfCheckedId.has(event.id));

        for (let event of this.selectedEvents) {
          if(event.resource.member === 'User') {
            event.user_id = event.resource.id;
          }
          if(event.resource.member === 'Team') {
            event.team_id = event.resource.id;
          }
        }

        this.api.setBulkTask(this.selectedEvents).subscribe(value => {
          this.message.success('ICS Import war erfolgreich');
          this.loading = false;
          this.modalService.closeAll();
        }, onerror => {
          this.message.error(onerror);
          console.log(onerror);
        });
      },
      nzCancelText: 'Abbrechen'
    });


  }

  public takeOverForAll(resource: User | Team): void {
    if (resource) {
      this.events.forEach(event => {
        event.resource = resource;
      });
      this.selectedEvents = this.events.slice();
      this.refreshCheckedStatus();
    }
  }

  public takeOverReference(reference: Object | Contact): void {
    if (reference) {
      switch (reference.member) {
        case 'Object':
          this.events.forEach(event => {
            event.object = reference;
            event.object_id = reference.id;
            event.reference = reference;
          });
          this.selectedEvents = this.events.slice();
          this.refreshCheckedStatus();
          break;

        case 'Contact':
          this.events.forEach(event => {
            event.contact = reference;
            event.contact_id = reference.id;
            event.reference = reference;
          });
          this.selectedEvents = this.events.slice();
          this.refreshCheckedStatus();
          break;
      }
    }
  }

  public referenceSelected(value: Object | Contact, event: Task): void {
    switch (value.member) {
      case 'Object':
        event.object_id = value.id;
        event.object = value;
        event.contact_id = null;
        event.contact = null;
        break;
      case 'Contact':
        event.contact_id = value.id;
        event.contact = value;
        event.object_id = null;
        event.object = null;
        break;
    }
  }

  pre(): void {
    this.current -= 1;
    this.events = [];
    this.selectedEvents = [];
    this.listOfCurrentPageData = [];
    this.uploadCompleted = false;
  }

  public onAllChecked(checked: boolean): void {
    this.events.forEach((event) => this.onItemChecked(event.id, checked));
    this.refreshCheckedStatus();
  }

  public updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  public onCurrentPageDataChange(listOfCurrentPageData: any[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  public refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.length > 0 && this.listOfCurrentPageData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = !this.checked && this.listOfCurrentPageData.some(({id}) => this.setOfCheckedId.has(id));
  }

  public onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public isValid(): boolean {
    let valid = true;
    let selectedEvents = this.events.filter(event => this.setOfCheckedId.has(event.id));
    for (let event of selectedEvents) {
      if (!event.resource) {
        valid = false;
        break;
      }
    }
    return valid;
  }
}
